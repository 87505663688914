import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const QuoteSection = ({ _data }) => {
  let result = {}
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(quoteText)/" } }) {
          nodes {
            exports {
              quoteText {
                description
                icon
                name
                paragraph1
                paragraph2
              }
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].exports.quoteText
  } catch (error) {
    result = _data
  }
  return (
    <div
      id="QuoteSection"
      className=" sect QuoteSection  "
      data-testid="QuoteSection"
    >
      <p className="para">
        {result.paragraph1}
        <br />
        {result.paragraph2}
      </p>
      <div className="d-flex flex-row justify-content-center pt-3 ">
        <img
          alt="Maxapps: partners"
          title="Maxapps: partners"
          src={result.icon}
          className="imag mx-3 "
          data-testid="iconPartner"
        />
        <div className="p-0">
          <p className="Name p-0 m-0">{result.name}</p>
          <p className="Description p-0 m-0">{result.description}</p>
        </div>
      </div>
    </div>
  )
}

export default QuoteSection
