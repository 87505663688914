import { Link } from "gatsby"
import React from "react"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"

const Section1 = ({ _data }) => {
  let data = []
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(contact-section1)/" }) {
          exports {
            data {
              image
              title
              subTitle
              to
            }
          }
        }
      }
    `)

    data = result.mdx.exports.data
  } catch (error) {
    data = _data
  }
  return (
    <div
      id="Section1"
      className="Section1 d-flex px-2 pt-5 px-lg-5 container-fluid"
      data-testid="Section1"
    >
      <div className=" row   ">
        {data?.map((item, index) => {
          return (
            <div className="col-10 col-lg-10 mb-5 container " key={index}>
              <div className="itemcontainer">
                <div className="d-flex">
                  <img
                    src={item.image}
                    alt={`IBM Maximo: ${item.title}`}
                    title={`IBM Maximo: ${item.title}`}
                    data-testid={`image${index}`}
                    className="image"
                  />
                  <h2 className="title">{item.title}</h2>
                </div>
                <p
                  className="subTitle "
                  dangerouslySetInnerHTML={{ __html: item.subTitle }}
                ></p>

                <Link
                  to={item.to}
                  className="linkStyle"
                  data-testid={`link${index}`}
                >
                  Learn more
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Section1
