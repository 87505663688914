import React from "react"

import ContactForm from "../../forms/contact-form"

import "./index.scss"
const FormContact = ({
  // title1 = "Any inquiery ?",
  title2 = "Please fill out the form below",
  withDateTimePickerField = false,
}) => {
  return (
    <div
      id="FormContact"
      className="form-contact d-flex justify-content-center "
    >
      <div className="w500">
        {/* <h2 className="title-p1 text-left container ">{title1}</h2> */}
        <p className="title-p2 text-left container">{title2}</p>
        <ContactForm withDateTimePickerField={withDateTimePickerField} />
      </div>
    </div>
  )
}

export default FormContact
