import React from "react"

import Section1 from "./section1"
import FormContact from "./form"

import "./index.scss"
const ContactComponent = props => {
  const { title1, title2, withDateTimePickerField } = props
  return (
    <div id="contactComponent" className="contact-component py-5">
      <div className="row">
        <div className="col-md-6 col-12">
          <FormContact
            title1={title1}
            title2={title2}
            withDateTimePickerField={withDateTimePickerField}
          />
        </div>

        <div className="col-md-6 col-12">
          <Section1 />
        </div>
      </div>
    </div>
  )
}

export default ContactComponent
