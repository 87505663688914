import React from "react"
import { Helmet } from "react-helmet"

import Seo from "../seo"
import JoinSection from "./join-section"
import TitleSection from "./title-section"
import WhyPartnerSection from "./WhyPartnerSection"

const Partners = () => {
  return (
    <div className="py-5">
      <Seo
        title="Become partner"
        description="Ready To Partner With MAXapps? Start Building Efficient Processes for Your Users."
        title2={true}
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXApps Partners" />
        <meta
          property="og:description"
          content="Grow with MAXapps.Become a MAXapps partner and accelerate your business."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/Partners.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/partners" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXApps Partners" />
        <meta
          name="twitter:description"
          content="Grow with MAXapps.Become a MAXapps partner and accelerate your business."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/Partners.jpg"
        />
      </Helmet>
      <TitleSection />
      <WhyPartnerSection />
      <JoinSection />
    </div>
  )
}

export default Partners
