import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const Card = ({ _card }) => {
  let data = []
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(card-partner)/" }) {
          exports {
            data {
              subTitle
              title
              image
            }
          }
        }
      }
    `)

    data = result.mdx.exports.data
  } catch (error) {
    data = _card
  }

  return (
    <div id="Card" className="row CardUsesCase " data-testid="Card">
      {data.map((elt, index) => {
        return (
          <div className="col-lg-4  col-md-6 text-center  " key={index}>
            <img
              src={elt.image}
              alt="Maxapps: partners"
              title="Maxapps: partners"
              data-testid={index}
              className="image"
            />
            <h3>{elt.title}</h3>
            <p className="par  mx-2">{elt.subTitle}</p>
          </div>
        )
      })}
    </div>
  )
}

export default Card
