import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ContactComponent from "../../contact-us/contact-component"

import "./index.scss"
const JoinSection = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(join-section)/" } }) {
          nodes {
            frontmatter {
              titleBottompartner
              titleButtonpartner
              subTitleBottomPartner
              to
            }
          }
        }
      }
    `)
    data = result.allMdx.nodes[0].frontmatter
  } catch {
    data = _data
  }
  return (
    <div
      id="JoinSection"
      className=" JoinSection my-5 py-5 "
      data-testid="JoinSection"
    >
      <div className="titleConatiner">
        <h2 className="h2-title">{data.titleBottompartner}</h2>
      </div>
      <div className="subTitleContainer pt-3">
        <p
          className="subTitle"
          dangerouslySetInnerHTML={{ __html: data.subTitleBottomPartner }}
        ></p>
      </div>
      <ContactComponent
        title1="Ready To Partner With MAXapps?"
        title2="Let's schedule a call"
        withDateTimePickerField={true}
      />
    </div>
  )
}

export default JoinSection
