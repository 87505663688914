import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const TitleSection = ({ _data }) => {
  let result = {}
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(title-section)/" } }) {
          nodes {
            exports {
              titleSection {
                bottomTitlepartner
                midTitlepartner
                topTitlePartner
              }
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].exports.titleSection
  } catch (error) {
    result = _data
  }
  return (
    <div
      id="TitleSection"
      className="TitleSection mt-3 "
      data-testid="TitleSection"
    >
      <div className="container">
        <h1 className="title">
          {result.topTitlePartner} <br />
          <span className="subTitleMain">{result.midTitlepartner}</span>
        </h1>
        <p>{result.bottomTitlepartner}</p>
      </div>
    </div>
  )
}

export default TitleSection
