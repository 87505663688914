import React from "react"
import Partners from "../components/partners"
import Layout from "../components/layout/t1/layout"

const partners = () => {
  return (
    <Layout>
      <Partners />
    </Layout>
  )
}

export default partners
