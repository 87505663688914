import React from "react"
import Card from "./card"
import QuoteSection from "./QuoteSection"
import "./index.scss"

const WhyPartnerSection = () => {
  return (
    <div id="WhyPartnerSection" className="WhyPartnerSection  ">
      <div>
        <h2 className="titleSection">Why Partner with MAXapps ?</h2>
      </div>
      <Card />
      <QuoteSection />
    </div>
  )
}

export default WhyPartnerSection
